import React, { Component } from 'react';
import { Link  } from 'react-router-dom';
import {Accordion, Card, Header, Button} from 'react-bootstrap';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import ClientLogo from '../elements/client-logo/client-logo-1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import Icon1 from '../../images/icon/contact/icon1.png';
import Icon2 from '../../images/icon/contact/icon2.png';
import Icon3 from '../../images/icon/contact/icon3.png';

class Faq2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],
			imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
		};
	}

	componentDidMount() {
		const apiUrl = "https://mkrefox.com:8181/getSSSTekne/select";

		fetch(apiUrl)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ data });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});
			const apiUrl2 = "https://mkrefox.com:8181/getContactTekne/select/";

			fetch(apiUrl2)
				.then((response) => response.json())
				.then((data2) => {
					this.setState({ data2 });
				})
				.catch((error) => {
					console.error("API'den veri çekme hatası:", error);
				});

		}



	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">SSS</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li> SSS</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						<div className="section-area section-sp2">
							<div className="container">
								<div className="row">
									<div className="col-lg-8 col-md-7">
										<div className="heading-bx">
											<h6 className="title-ext m-b0">Cevapları Bulun</h6>
											<h3 className="title-head m-b0">Sıkça Sorulan Sorular?</h3>
											<div className="ttr-separator sepimg"></div>
											<p className="head-px2">Sorularınızın cevaplarına  ulaşabilirsiniz.</p>
										</div>



										<Accordion className="ttr-accordion gray" defaultActiveKey="0">
											
										{this.state.data.map((item) => (
											<Card>
												<Accordion.Toggle as={Card.Header} eventKey={item.ID}>
													<Link to="#">{item.Title}</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey={item.ID}>
													<Card.Body>
													{item.Description}	
														</Card.Body>
												</Accordion.Collapse>
											</Card>
													))}

										</Accordion>
									</div>
									<div className="col-lg-4 col-md-5">
										<aside className="sticky-top left-border-1">
											<div className="widget">
												<h6 className="widget-title">Dİğer Sayfalar</h6>
												<ul className="service-list style-2">
													<li><Link to="/hakkimizda">Hakkımızda <i className="las la-question-circle"></i></Link></li>
													<li><Link to="/iletisim">İletişim <i className="las la-question-circle"></i></Link></li>
												    <li className="active"><Link to="/sss">SSS<i className="las la-quote-left"></i></Link></li>
												</ul>
												<Link to="/rezervasyon" className="btn btn-block m-t20">Rezervasyon</Link>
											</div>


											
											<div className="widget">
												<h6 className="widget-title">İletİŞİm</h6>
												{this.state.data2.map((item) => (
													<ul className="contact-infolist" key={item.ID}>
														<li>
															<img src={Icon1} alt="" className="mCS_img_loaded" />
															<h6 className="contact-title">İletişim Numarası</h6>
															<p>{item.Phone} </p>
														</li>
														<li>
															<img src={Icon2} alt="" className="mCS_img_loaded" />
															<h6 className="contact-title">E-mail Adres</h6>
															<Link to="#">{item.Mail}</Link><br />

														</li>
														<li>
															<img src={Icon3} alt="" className="mCS_img_loaded" />
															<h6 className="contact-title">Adres</h6>
															<p>{item.Adress}</p>
														</li>
													</ul>
												))}
											</div>
										</aside>
									</div>
								</div>
								
							</div>
						</div>
			
					
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default Faq2;