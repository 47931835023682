import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import BlogSidebar from '../elements/blog/blog-sidebar';

// Images
import DefaultPic1 from "../../images/blog/default/pic1.jpg"
import GalleryPic2 from "../../images/gallery/pic2.jpg"
import GalleryPic5 from "../../images/gallery/pic5.jpg"
import TestimonialsPic1 from "../../images/testimonials/pic1.jpg"
import TestimonialsPic2 from "../../images/testimonials/pic2.jpg"
import TestimonialsPic3 from "../../images/testimonials/pic3.jpg"

class BlogDetailsSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {

			data3: [],
			imageUrl: 'https://tekneportapi.stechomeyazilim.com/Uploads/',
		};
	}
	render() {

		const { _item } = this.props.location.state;

		return (
			<>
				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<div className="page-banner page-banner-sm">
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-black">Organizasyon&Tur</h1>
								<div className="breadcrumb-row text-black">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Detay</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="content-block" id="content-area">

						<div className="section-area section-sp2 bg-white">
							<div className="container">
								<div className="row">
									<div className="col-md-12 col-lg-8 col-xl-8 m-md-b50 wow fadeInUp" data-wow-delay="0.2s">
										{/* <!-- blog start --> */}
										<div className="blog-lg blog-single">
											<div className="action-box blog-lg">

												<img  src={this.state.imageUrl + _item.ExtraImage} alt="" />

											</div>
											<div className="info-bx">

												<div className="ttr-post-title">
													<h4 className="post-title">{_item.Title}</h4>
												</div>
												<div className="ttr-post-text">
													<blockquote className="wp-block-quote">
														<p>{_item.Description} </p>
													</blockquote>
													<p>{_item.LongDesc} </p>
												</div>

											</div>
										</div>

										{/* <!-- blog END --> */}
									</div>

									<div className="col-md-12 col-lg-4 col-xl-4 sticky-top wow fadeInUp" data-wow-delay="0.4s">
										<BlogSidebar />
									</div>

								</div>
							</div>
						</div>

					</div>

				</div>

				<Footer1 />

			</>
		);
	}
}

export default BlogDetailsSidebar;