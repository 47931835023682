import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LogoWhite from "../../../images/logo-white.png"
import IconImg1 from "../../../images/icon/contact/icon1.png"
import IconImg2 from "../../../images/icon/contact/icon2.png"
import IconImg3 from "../../../images/icon/contact/icon3.png"

class Footer1 extends Component {
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://mkrefox.com:8181/getContactSacEkimi/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}

	render() {
		return (
			<>
				{/* <!-- Footer ==== --> */}
				<footer className="footer-style2">
					<div className="footer-top bt0">
						<div className="container">

							{this.state.data2.map((item) => (
								<div className="row align-items-center footer-info">
									<div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
										<div className="feature-container left footer-info-bx">
											<div className="feature-lg text-white">
												<span className="icon-cell"><img src={IconImg3} alt="" /></span>
											</div>
											<div className="icon-content">
												<h5  className="ttr-tilte">Adres</h5>
												<p style={{color:'#8a6d46'}}>{item.Address}</p>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
										<div className="feature-container left footer-info-bx">
											<div className="feature-lg text-white">
												<span className="icon-cell"><img src={IconImg1} alt="" /></span>
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Telefon Numarası</h5>
												<p style={{color:'#8a6d46'}}>{item.PhoneNumber}</p>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6 m-sm-b30">
										<div className="feature-container left footer-info-bx">
											<div className="feature-lg text-white">
												<span className="icon-cell"><img src={IconImg1} alt="" /></span>
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Telefon Numarası 2</h5>
												<p style={{color:'#8a6d46'}}>{item.PhoneNumberSecond}</p>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6 text-left text-lg-right">
										<Link to="/iletisim" className="btn btn-long d-sm-inline-block">İletişim</Link>
									</div>
								</div>
							))}

						</div>
						
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-left">
									<p className="m-b0">Copyright © 2024
									Stechome Software
										All Rights Reserved.</p>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-right">
									<ul className="link-inline">
										<li><Link style={{color:'#8a6d46'}}to="/">Anasayfa</Link></li>
										<li><Link style={{color:'#8a6d46'}} to="/alopesiareata">Alopesi Areata</Link></li>
								
										<li><Link style={{color:'#8a6d46'}} to="/iletisim">İletişim</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</>
		);
	}
}

export default Footer1;
