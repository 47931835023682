import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import About from '../elements/about/about3';
import Portfolio from '../elements/portfolio/portfolio-box1';
import ClientLogo from '../elements/client-logo/client-logo-1';
import PricingTable from '../elements/pricingtable/pricingtable';

class About3 extends Component {
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://mkrefox.com:8181/getInformationSacEkimi/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}

	render() {

		return (
			<>

				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<div className="page-banner page-banner-sm">
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-black">REFOX</h1>
								<div className="breadcrumb-row text-black">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
				
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="content-block" id="content-area">

						<div className="section-area section-sp2">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-12 col-md-12 wow fadeIn m-md-b40" data-wow-delay="0.3s" style={{ visibility: "visible", animationDelay: "0.3s", animationName: "fadeIn" }}>
										<div className="heading-bx m-b20">

											<h2 className="title-head m-b0">ALOPESİ AREATA</h2>
									
											{this.state.data2.map((item) => (
										
										
											<p className="head-px2">{item.InformationText}</p>
									
											))}
									
											</div>
										{/* <!-- start progress bar item --> */}
									
									</div>

								</div>
							</div>
						</div>





					</div>

				</div>

				<Footer1 />

			</>
		);
	}
}

export default About3;