import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Accordion, Card, Header, Button} from 'react-bootstrap';
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
// Images
import AboutPic7 from "../../../images/about/pic7.jpg"

class MissionVision extends Component{
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://tekneportapi.stechomeyazilim.com/Uploads/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://mkrefox.com:8181/getAboutTekne/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}
	render(){
		return(
			<>

{this.state.data2.map((item) => (
				<div className="row">
			
				
					<div className="col-md-6 section-sp1 p-r30 p-xs-r15">
						<div className="heading-bx">
							<h6 className="title-ext m-b0">Tekneport</h6>
							<h2 className="title-head m-b0">{item.About_Title}</h2>
							<div className="ttr-separator sepimg"></div>
							<ReactHtml  html={base64.decode(base64.decode(item.About_Desc))} />
						
						</div>
				
			
					</div>
					<div className="col-md-6 p-lr0 ">
					<img  src={this.state.imageUrl+item.About_Image} alt=""/>
					</div>
					</div>
		
		
				))}
			</>
		);
	}
}

export default MissionVision;
