import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import axios from 'axios';
// Images
import Banner1 from '../../images/banner/banner1.jpg';
import Icon1 from '../../images/icon/contact/icon1.png';
import Icon2 from '../../images/icon/contact/icon2.png';
import Icon3 from '../../images/icon/contact/icon3.png';

class GetInTouch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
            countries: [],
            selectedCountry: '',
            selectedDate: '',
            selectedTime: '',
        };
    }
    handleDateChange = (event) => {
        this.setState({ selectedDate: event.target.value });
    };

    handleTimeChange = (event) => {
        this.setState({ selectedTime: event.target.value });
    };

    componentDidMount() {
        axios.get('https://restcountries.com/v3.1/all')
            .then(response => {
                // API'den gelen ülkeleri state'e kaydet
                this.setState({ countries: response.data });
            })
            .catch(error => {
                console.error('API hatası:', error);
            });
        const apiUrl2 = "https://mkrefox.com:8181/getContactTekne/select/";

        fetch(apiUrl2)
            .then((response) => response.json())
            .then((data2) => {
                this.setState({ data2 });
            })
            .catch((error) => {
                console.error("API'den veri çekme hatası:", error);
            });

    }
    handleCountryChange = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    render() {
        const { selectedDate, selectedTime } = this.state;
        const maxPersonCount = 500;
        const personCountOptions = [];

        for (let i = 1; i <= maxPersonCount; i++) {
            personCountOptions.push(
                <option key={i} value={i}>
                    {i} kişi
                </option>
            );
        }


        return (
            <>
                <Header1 />

                {/* <!-- Content --> */}
                <div className="page-content bg-white">

                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">Rezervasyon</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>Rezervasyon</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">

                        <div className="section-area bg-gray section-sp2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 col-md-7">
                                        <div className="heading-bx">
                                            <h6 className="title-ext m-b0">Yat Kiralama</h6>
                                            <h3 className="title-head m-b0">Online Rezervasyon Talebi</h3>
                                            <div className="ttr-separator sepimg"></div>
                                            <p className="head-px2">Aşağıdaki formu kullanarak dakikalar içerisinde yat kiralama fiyatını öğrenebilir ve istediğiniz tarih için rezervasyon talebi gönderebilirsiniz</p>
                                        </div>
                                        <div className="row m-b30">
                                            <div className="col-md-12">
                                                <form className="ajax-form form-area-box get-in-touch" action="script/contact.php">
                                                    <div className="ajax-message"></div>
                                                    <div className="row placeani">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <label>İsim*</label>
                                                                    <input name="name" type="text" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <label>E-mail*</label>
                                                                    <input name="phone" type="email" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <label>Telefon Numarası*</label>
                                                                    <input name="phone" type="text" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="row m-b20">
                                                                <div className="col-md-12 col-lg-12">
                                                                    <h5>Ekstralar</h5>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="custom-control custom-checkbox m-b10">
                                                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing1" />
                                                                        <label className="custom-control-label" htmlFor="customControlAutosizing1">Pasta (650 Tl)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="custom-control custom-checkbox m-b10">
                                                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing2" />
                                                                        <label className="custom-control-label" htmlFor="customControlAutosizing2">Lazer Gösterisi (1500 TL)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="custom-control custom-checkbox m-b10">
                                                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing3" />
                                                                        <label className="custom-control-label" htmlFor="customControlAutosizing3">Volkan Gösterisi (400 TL)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="custom-control custom-checkbox m-b10">
                                                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing4" />
                                                                        <label className="custom-control-label" htmlFor="customControlAutosizing4">Gül 25 Adet (1500 TL)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="custom-control custom-checkbox m-b10">
                                                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing5" />
                                                                        <label className="custom-control-label" htmlFor="customControlAutosizing5">Fasıl (5000 TL)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="custom-control custom-checkbox m-b10">
                                                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing6" />
                                                                        <label className="custom-control-label" htmlFor="customControlAutosizing6">Fotoğrafçı (8500 TL)</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="custom-control custom-checkbox m-b10">
                                                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing6" />
                                                                        <label className="custom-control-label" htmlFor="customControlAutosizing6">Kemancı (2500 TL)</label>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="custom-control custom-checkbox m-b10">
                                                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing6" />
                                                                        <label className="custom-control-label" htmlFor="customControlAutosizing6">Uçan Balon (2000 TL)</label>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="custom-control custom-checkbox m-b10">
                                                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing6" />
                                                                        <label className="custom-control-label" htmlFor="customControlAutosizing6">Yata Transfer (3250 TL)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Tarih:</label>
                                                                <div className="input-group">

                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        value={selectedDate}
                                                                        onChange={this.handleDateChange}
                                                                    />


                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Saat:</label>
                                                                <div className="input-group">


                                                                    <input
                                                                        type="time"
                                                                        className="form-control"
                                                                        value={selectedTime}
                                                                        onChange={this.handleTimeChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <select value={this.state.selectedCountry} onChange={this.handleCountryChange}>
                                                                        <option value="">Liman Seç</option>
                                                                    
                                                                        <option value="Arnavutköy">Arnavutköy</option>
                                                                        <option value="Bebek">Bebek</option>
                                                                        <option value="Kuruçeşme">Kuruçeşme</option>
                                                                        <option value="Kuleli">Kuleli</option>
                                                                        <option value="Beylerbeyi">Beylerbeyi</option>
                                                         
                                                                    </select>
                                                                  
                                                                </div>     </div>     </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <select name="quote-request-reach">
                                                                        <option value="">Kaç Saatlik Bir Tur Olacak</option>
                                                                        <option value="2saat">2 saat</option>
                                                                        <option value="3saat">3 saat</option>
                                                                        <option value="4saat">4 saat</option>
                                                                        <option value="5saat">5 saat</option>
                                                                        <option value="6saat">6 saat</option>
                                                                        <option value="7saat">7 saat</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <select name="quote-request-hear">
                                                                        <option value="">Kaç Kişi Olacaksınız</option>
                                                                        {personCountOptions}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <select name="quote-request-hear">
                                                                        <option value="">Yemek Seçenekleri</option>
                                                                        <option value="kahvalti">Kahvaltı (485 TL)</option>
                                                                        <option value="sarkuteri">Şarküteri Tabağı (540 TL)</option>
                                                                        <option value="yemek">Yemek (650 TL)</option>
                                                                        <option value="no">İstemiyorum</option>


                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <label>Eklemek İstediğiniz Notlar</label>
                                                                    <textarea className="form-control" required></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <button name="submit" type="submit" value="Submit" className="btn button-md"> Gönder</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-5">
                                        <aside className="sticky-top left-border-1">
                                            <div className="widget">
                                                <h6 className="widget-title">Dİğer Sayfalar</h6>
                                                <ul className="service-list style-2">
                                                    <li><Link to="/hakkimizda">Hakkımızda <i className="las la-question-circle"></i></Link></li>
                                                    <li><Link to="/sss">SSS <i className="las la-question-circle"></i></Link></li>
                                                    <li className="active"><Link to="/iletisim">İletişim<i className="las la-quote-left"></i></Link></li>
                                                </ul>
                                                <Link to="/rezervasyon" className="btn btn-block m-t20">Rezervasyon</Link>
                                            </div>



                                            <div className="widget">
                                                <h6 className="widget-title">İletİŞİm</h6>
                                                {this.state.data2.map((item) => (
                                                    <ul className="contact-infolist" key={item.ID}>
                                                        <li>
                                                            <img src={Icon1} alt="" className="mCS_img_loaded" />
                                                            <h6 className="contact-title">İletişim Numarası</h6>
                                                            <p>{item.Phone} </p>
                                                        </li>
                                                        <li>
                                                            <img src={Icon2} alt="" className="mCS_img_loaded" />
                                                            <h6 className="contact-title">E-mail Adres</h6>
                                                            <Link to="#">{item.Mail}</Link><br />

                                                        </li>
                                                        <li>
                                                            <img src={Icon3} alt="" className="mCS_img_loaded" />
                                                            <h6 className="contact-title">Adres</h6>
                                                            <p>{item.Adress}</p>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                        </aside>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>

                </div>

                <Footer1 />

            </>
        );
    }
}

export default GetInTouch;