import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import Slider from '../elements/slider/slider1';

import About from '../elements/about/about2';
import Testimonial from '../elements/testimonial/testimonial1';



class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			data3: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://mkrefox.com:8181/getContactTekne/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


		const apiUrl3 = "https://mkrefox.com:8181/getNewsSacEkimi/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


	}

	render() {
		return (
			<>

				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<Slider />

					{/* <!-- Main Slider --> */}
					<div className="content-block" id="content-area">




						<div className="section-area section-sp3 bg-white">
							<div className="container">
								<About />
							</div>
						</div>




						<div className="section-area bg-primary section-sp2 client-appoint-box mt-5">
							<div className="container" >
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<div className="heading-bx text-white">
											<h6 style={{ color: 'white' }} className="title-ext m-b0">İLETİşİM</h6>
											<div className="ttr-separator sepimg2"></div>
										</div>
										<div className="swiper-container client-area-box">
											<Testimonial />
										</div>
									</div>
									<div className="col-lg-6 col-md-12">
										<form className="ajax-form" action="script/contact.php">
											<div className="apoint-btn-box">Bize Yazın</div>
											<div className="ajax-message"></div>
											<div className="heading-bx text-white">
												<h6 className="title-ext m-b0">REFOX</h6>
												<h2 className="title-head m-b0">Bizimle iletişime geçiniz</h2>
												<div className="ttr-separator sepimg2"></div>
											</div>
											<div className="row placeani appoint-bx1">
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="name" type="text" required className="form-control valid-character" placeholder="İsminiz" style={{ color: 'black' }} />
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="email" type="email" className="form-control" required placeholder="Email Adresiniz" style={{ color: 'black' }} />
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="phone" type="text" required className="form-control int-value" placeholder="Telefon Numaranız" style={{ color: 'black' }} />
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<div className="input-group input-bx">
															<textarea name="message" rows="4" className="form-control" placeholder="Mesajınız" required style={{ color: 'black' }} ></textarea>
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<button name="submit" type="submit" value="Submit" className="btn button-md"> Mesaj Gönder</button>
												</div>
											</div>
										</form>

									</div>
								</div>
							</div>
						</div>

						<div className="section-area bg-white section-sp1 blog-area">
							<div className="container">
								<div className="row heading-bx align-items-end">
									<div className="col-lg-8 col-md-9">
										<h6 className="title-ext m-b0">REFOX</h6>
										<h2 className="title-head m-b0">
											Basında Biz</h2>
										<div className="ttr-separator sepimg"></div>
									</div>
									<div className="col-lg-4 col-md-3">
										<Link to="/basindabiz" className="btn float-right">Daha Fazlası İçin</Link>
									</div>
								</div>
								<div className="row">


									{this.state.data3.slice(0, 1).map((item) => (
										<div className="col-md-6">
											<div className="recent-news">
												<div className="action-box">
													<Link to={{ pathname: '/basindabiz', state: { _item: item } }} >
														<img src={this.state.imageUrl + item.NewsImage} alt="" /></Link>
												</div>

											</div>
										</div>
									))}


									<div className="col-md-6">



										<div className="side-post">

											{this.state.data3.slice(3, 5).map((item) => (

												<div className="blog-post blog-md clearfix wow fadeInUp" data-wow-delay="0.2s">
													<div className="ttr-post-media">
														<Link to={{ pathname: '/basindabiz', state: { _item: item } }} >
															<img src={this.state.imageUrl + item.NewsImage} alt="" /></Link>
													</div>

												</div>
											))}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

				</div>


				<Footer1 />
			</>
		);
	}
}

export default Index;