import React, { Component, useState, handleShow } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import Logo from "../../../images/logo.png"

class Header1 extends Component {
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://mkrefox.com:8181/getContactSacEkimi/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}

	render() {
		return (
			<>

				{/* <!-- Header Top ==== --> */}
				<header className="header rs-nav">
					<div className="top-bar">
						<div className="container">
							<div className="row d-flex justify-content-between">
								<div className="topbar-left">
									{this.state.data2.map((item) => (
										<ul>
											<li><Link to="/"><i className="la la-phone"></i>{item.PhoneNumber}</Link></li>

											<li><Link to="/"><i className="las la-phone"></i>{item.PhoneNumberSecond}</Link></li>
										</ul>
									))}


								</div>

							</div>
						</div>
					</div>
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<Link to="/"><img style={{width:80}} src={Logo} alt="" /></Link>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
									<div className="menu-logo">
										<Link to="/"><img src={Logo} alt="" /></Link>
									</div>
									<ul className="nav navbar-nav">
										<li ><Link to="/">Anasayfa</Link>
										</li>
										<li ><Link to="/basindabiz">Basında Biz</Link>
										</li>

										<li ><Link to="/alopesiareata">Alopesi Areata</Link>

										</li>
										<li ><Link to="/resmiizinler">Resmi İzinler</Link>
										</li>
										<li ><Link to="/iletisim">İletişim</Link>
										</li>



									</ul>
								
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> */}
					<div className="nav-search-bar">
						<form action="#">

						</form>
						<span id="search-remove"></span>
					</div>
				</header>
				{/* <!-- Header Top END ==== --> */}
			</>
		);
	}
}

export default Header1;
