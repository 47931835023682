import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Slider1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data2: [],
            imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
        };
    }

    componentDidMount() {
        const apiUrl2 = "https://mkrefox.com:8181/getSliderSacEkimi/select/";

        fetch(apiUrl2)
            .then((response) => response.json())
            .then((data2) => {
                this.setState({ data2 });
            })
            .catch((error) => {
                console.error("API'den veri çekme hatası:", error);
            });
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000
        };

        const sliderStyle = {
            maxWidth: '100%',
            margin: '0 auto',
            padding: '0 15px', // Ekran kenarlarından boşluk bırakmak için
        };

        const sliderItemStyle = {
            position: 'relative',
            width: '100%',
            height: '500px', // Slider öğesinin yüksekliği, ihtiyacınıza göre ayarlayın
            overflow: 'hidden', // Görüntüyü tam olarak kesmek için
        };

        const imageStyle = {
            width: '100%',
            height: '100%',
            objectFit: 'contain', // Görüntüyü kapsayıcıya sığdırmak için
        };

        return (
            <div style={sliderStyle}>
                <Slider {...settings} className="tt-slider slider-one slider-sp0">
                    {this.state.data2.map((item, index) => (
                        <div className="slider-item" key={index} style={sliderItemStyle}>
                            <div className="slider-thumb">
                                <img src={this.state.imageUrl + item.Slider_Url} alt={`Slider Image ${index}`} style={imageStyle} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}

export default Slider1;
