import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Image1 from "../../../images/about/pic11.jpg"
import Image2 from "../../../images/portfolio/portfolio-1/image_7.jpg"
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
class About1 extends Component{
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.com/Uploads/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://mkrefox.com:8181/getInformationSacEkimi/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}
	render(){
		return(
			<>
				{this.state.data2.map((item) => (
				<div className="row">
			
				
					<div className="col-md-6">
						<div className="heading-bx m-b20">
							<h6 className="title-ext m-b0">REFOX</h6>
							<h2 className="title-head m-b0">Alopesi Areata</h2>
							<div className="ttr-separator sepimg"></div>
							<p className="head-px2">{item.InformationText.length > 120 ? `${item.InformationText.slice(0, 120)}...` : item.InformationText}</p>

						
						</div>
						<Link to="/alopesiareata" className="btn">Daha Fazla Bilgi Al <i className="ti-hand-point-up"></i></Link>
				
					</div>
						
				</div>
					))}
			</>
		);
	}
}

export default About1;
